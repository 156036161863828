import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, TextField, Typography } from "@mui/material";
import { KeyRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { handleAlert } from "../../Redux/alertSlice";
import { handleModalOpen } from "../../Redux/LoginSlice";
import {
  getUserDetails,
  handleExistUser,
  registerUsers,
} from "../../Redux/festures/userSlice";
import { decryptKey, encryptKey } from "../../Utils/dataFunction";
// import { useNavigate } from "react-router-dom";

const OtpPage = ({
  setClick,
  setIsShow,
  setOtpVerify,
  sendOTP,
  minutes,
  seconds,
  setMobileNumber,
}) => {
  const credential = localStorage.getItem("credential");

  const userId = localStorage.getItem("isUserId");
  const otpData = localStorage.getItem("isOtp");
  const isOtp = decryptKey(otpData);
  const dispatch = useDispatch();
  // console.log(isOtp , "asdfdsdaf")
  // const navigate = useNavigate();
  const handleFormSubmit = (values) => {
    dispatch(
      handleAlert({
        isOpen: true,
        type: isOtp === values.otp ? "success" : "error",
        msg:
          isOtp === values?.otp
            ? "Verify OTP Successfully"
            : "Enter invalid OTP",
      })
    );
    if (isOtp === values.otp) {
      localStorage.setItem("userVerify", true);
      dispatch(handleModalOpen(false));
      localStorage.removeItem("isUserIdDummy");
      setOtpVerify(false);
      setClick(true);
      setIsShow(true);
      localStorage.setItem("isOtp", null);
      // navigate("/");
      setMobileNumber(null);
      dispatch(handleExistUser(true));
      dispatch(getUserDetails(`/getUserById/${userId}`));
    } else {
      localStorage.setItem("userVerify", false);
    }
    // const result = await signIn("credentials", {
    //   redirect: false,
    //   ...values,
    // });

    // if (result.error) {
    //   console.error("Authentication failed:", result.error);
    // } else {
    //   console.log("Authentication successful:", result);
    //   // Redirect or perform other actions upon successful authentication
    // }
  };

  const isOtpSchema = yup.object().shape({
    otp: yup
      .string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits or numbers")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits"),
  });

  const initialValues1 = {
    otp: "",
  };
  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues1}
        validationSchema={isOtpSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            component="form"
            role="form"
            onSubmit={handleSubmit}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 30,
                fontWeight: 800,
                fontFamily: "Inter",
              }}
            >
              Verify otp
            </Typography>
            <Typography
              variant="body1"
              color="initial"
              sx={{
                fontSize: 14,
                fontWeight: 400,
                fontFamily: "Inter",
              }}
            >
              Verify your mobile number
            </Typography>
            <Box sx={{ display: "flex", gap: 2, mt: 4 }}>
              <TextField
                id="standard-basic"
                variant="standard"
                fullWidth
                required
                autoComplete="off"
                type={"text"}
                size="small"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.otp}
                name="otp"
                error={!!touched.otp && !!errors.otp}
                helperText={touched.otp && errors.otp}
                placeholder="Enter your 4 digit OTP"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 4);
                }}
                sx={{
                  "& .MuiInput-input": {
                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        "-moz-appearance": "textfield",
                      },
                  },
                  "& .MuiInputBase-root": {
                    height: 45,
                    //   border: 2,
                    //   borderColor: "gray",
                    fontSize: 14,
                    fontFamily: "Inter",
                    borderRadius: "15px",
                    width: { xs: 280, sm: 280, md: 400, xl: 400 },
                    gap: 1,
                    fontWeight: 600,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    outline: "none",
                    border: "none",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <inputAdornment position="start">
                      {/* <SearchIcon /> */}
                      <KeyRounded sx={{ fontSize: 20 }} />
                    </inputAdornment>
                  ),
                  autoComplete: "new-password",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                mt: 1.5,
                gap: 1,
              }}
            >
              <Typography
                variant="body1"
                color="initial"
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                Resend the OTP in
              </Typography>
              {seconds > 0 || minutes > 0 ? (
                <Typography
                  variant="body2"
                  sx={{ color: "red", fontWeight: 600 }}
                  component="span"
                >
                  {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Typography>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  // onClick={() => setClick(false)}
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    // setIsLoading(true);

                    dispatch(
                      registerUsers({
                        url: `${process.env.REACT_APP_API}/sendOtp`,
                        data: JSON.parse(credential),
                      })
                    ).then((isData) => {
                      // console.log(isData, "isData");
                      dispatch(
                        handleAlert({
                          isOpen: true,
                          type: isData?.payload?.success ? "success" : "error",
                          msg: isData?.payload?.message,
                        })
                      );
                      if (isData?.payload?.success) {
                        localStorage.setItem(
                          "isOtp",
                          encryptKey(isData?.payload?.otp)
                        );
                        sendOTP();
                      }
                    });
                    // setIsLoading(false);
                  }}
                >
                  resend
                </Button>
              )}
            </Box>
            <Button
              type="submit"
              disabled={!!touched.otp && !!errors.otp && values.otp !== ""}
              variant="contained"
              color="primary"
              sx={{
                width: "40%",
                borderRadius: 5,
                textTransform: "capitalize",
                mt: 4,
              }}
            >
              Verify
            </Button>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default OtpPage;
